import { template as template_84e2c83a21d744a397126ff167e831fd } from "@ember/template-compiler";
const FKLabel = template_84e2c83a21d744a397126ff167e831fd(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
