import { template as template_a56907e07f9b457c9301e5e2c2ca013d } from "@ember/template-compiler";
const FKText = template_a56907e07f9b457c9301e5e2c2ca013d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
