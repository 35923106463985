import { template as template_b20ac8bde1574edea0544df33e40f34e } from "@ember/template-compiler";
const FKControlMenuContainer = template_b20ac8bde1574edea0544df33e40f34e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
